@import './variables';

/*=========== Fonts ==============*/
@font-face {
   font-family: 'ProductSans';
   font-style: normal;
   font-weight: 500;
   src: url('./_assets/fonts/Product\ Sans\ Regular.ttf');
}
@font-face {
   font-family: 'ProductSans';
   font-style: normal;
   font-weight: 600;
   src: url('./_assets/fonts/Product\ Sans\ Bold.ttf');
}
@font-face {
   font-family: 'FuturaMdBt';
   font-style: normal;
   font-weight: 500;
   src: url('./_assets/fonts/FuturaMediumBT.ttf');
}
@font-face {
   font-family: 'FuturaMdBt';
   font-style: normal;
   font-weight: 600;
   src: url('./_assets/fonts/FuturaBoldBT.ttf');
}

html {
   margin: 0;
   padding: 0;
   width: 100%;
   height: 100%;

   body {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      font-size: 1rem;
      // font-family: $font;
      background: $background-15;
      box-sizing: border-box;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;

      & > * {
         margin: 0;
         padding: 0;
         box-sizing: border-box;
      }

      #root {
         width: 100%;
         height: 100%;
         display: flex;
         position: relative;
         .body {
            &.content {
               height: calc(100% - 5rem);
               margin-top: 5rem !important;
               overflow-y: scroll;
               padding-bottom: 5rem !important;

               &::-webkit-scrollbar {
                  width: 4px; /* Safari and Chrome */
               }
               &::-webkit-scrollbar-track {
                  background: transparent;
               }
               &::-webkit-scrollbar-thumb {
                  position: absolute;
                  background-color: $color-1;
                  border-radius: 30px;
               }
            }
            &.full {
               height: 100%;
               width: 100%;
            }
         }
         p, label, button, li {
            font-size: .82rem;
         }
         form {
            .img {
               height: 17rem;
            }
            input, textarea, span {
               font-size: .82rem;
            }
         }
         .tableBody {
            &::-webkit-scrollbar {
               width: 6px; /* Safari and Chrome */
            }
            &::-webkit-scrollbar-track {
               background: transparent;
            }
            &::-webkit-scrollbar-thumb {
               position: absolute;
               background-color: #999999;
               border-radius: 6px;
            }
         }
         .table-responsive {
            &::-webkit-scrollbar {
               width: 0px; /* Safari and Chrome */
               height: 0px; /* Safari and Chrome */
            }
            &::-webkit-scrollbar-track {
               background: transparent;
            }
            &::-webkit-scrollbar-thumb {
               position: absolute;
               background-color: $background-12;
               border-radius: 6px;
            }
            table {
               thead, tbody, tfoot, tr, td, th {
                  border: none;
               }
               thead {
                  tr {
                     background: $background-13;
                     th {
                        font-size: .82rem;
                        color: $color-9;
                        padding-top: .5rem;
                        padding-bottom: .5rem;
                     }
                  }
               }
               tbody {
                  border: none;
                  td {
                     font-size: .82rem;
                  }
                  tr {
                     // cursor: pointer;
                     transition: $trans3;
                     &:hover {
                        background: $background-12;
                     }
                     &:not(:last-child) {
                        border-bottom: 1px solid rgba(84, 95, 138, 0.2);
                     }
                     th, td {
                        font-size: .82rem;
                        font-family: $font1;
                        line-height: 1;
                     }
                     .img {
                        --val: 4rem;
                        width: var(--val);
                        height: var(--val);
                        border-radius: 4px;
                        overflow: hidden;
                     }
                     .mealName, td {
                        font-size: .82rem;
                        color: $color-1;
                        font-family: $font1;
                        line-height: 1.3;
                     }
                     .mealName {
                        // width: 9rem;
                     }
                     &.desc {
                        // width: 9rem;
                        overflow: hidden !important;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 2;
                     }
                     td {
                        .delivered {
                           color: $color-10;
                        }
                        .onDelivery {
                           color: $background-7;
                        }
                        .canceled {
                           color: $background-1;
                        }
                     }
                  }
               }
            }
         }
         @media (min-width: 992px) {
            .container-xl, .container-lg, .container-md, .container-sm, .container {
               max-width: 1800px;
            }
         }
      }

      &::-webkit-scrollbar {
         width: 4px; /* Safari and Chrome */
      }
      &::-webkit-scrollbar-track {
         background: transparent;
      }
      &::-webkit-scrollbar-thumb {
         position: absolute;
         background-color: #383838;
         border-radius: 30px;
      }
      @media screen and (max-width: 576px) {
         &::-webkit-scrollbar {
            width: 0; /* Safari and Chrome */
         }
      }
      .shimmer {
         background: linear-gradient(to right, #4F4F4F 8%, #4F4F4F 18%, #4F4F4F 33%) !important;
      }
   }
}

// @media screen and (max-width: 678px) {
//    h1, h2, h3, h4, h5, h6 {
//       font-size: 1.2rem !important;
//    }
//    p, span, input, .input, textarea, button, label, .label {
//       font-size: .9rem !important;
//    }
// }

h1, h2, h3, h4, h5, h6 {
   margin: 0;
   padding: 0;
   font-family: $font1;
}

p, span, small, sup, sub {
   margin: 0;
   padding: 0;
   font-family: $font1;
}

ul, ol {
   margin: 0;
   padding: 0;
   list-style-type: none;
   font-family: $font1;
}

form {
   box-shadow: none !important;
}

a {
   text-decoration: none;
   text-decoration-skip-ink: auto;
   font-family: $font1;
}

img {
   object-fit: cover;
}

input, textarea {
   &:focus, &:active {
      &::placeholder {
         opacity: 0.5;
      }
   }
}

textarea {
   resize: none;
}

button {
   border: none;
   background: transparent;
}

button, input, textarea {
   outline: none !important;
   font-family: $font1;
}
