@import '../../variables';

.login {
   height: 100vh;
   overflow: hidden;
   @media screen and (max-width: 767px) {
      height: 100%;
      overflow-y: scroll;
   }
   .logo {
      width: 4.5rem;
      top: 1rem;
      left: 1rem;
   }
   .img {
      .overlay {
         top: 0;
         left: 0;
         bottom: 0;
         right: 0;
         color: $white;
         // background: #ddd;
         .welcome {
            font-size: 1rem;
         }
         h1 {
            font-size: 3rem;
         }
         .desc {
            font-size: 1rem;
         }
      }
      .small {
         top: 2rem;
         right: 2rem;
         transform: scale(.5);
         z-index: 2;
      }
      .small2 {
         bottom: 2rem;
         left: -2rem;
         transform: scale(.5);
         z-index: 2;
      }
   }
}
