@import '../../variables';

.dashboard {
   animation: $fade1;
   .overview {
      background: #FFFFFF;
      border-radius: 8px;
      box-shadow: 0px 4px 37px rgba(229, 229, 229, 0.13);
      .totalOverview {
         .first, .last {
            position: relative;
            &::before, &::after {
               content: '';
               position: absolute;
               height: 1px;
               width: 20%;
               background: rgba(84, 95, 138, 0.2);
            }
            &::before {
               margin: auto;
               right: 5%;
               transform: rotate(90deg);
            }
            &::after {
               top: 110%;
               left: 35%;
               width: 30%;
            }
         }
         .last {
            &::before {
               margin: auto;
               right: 105%;
               transform: rotate(90deg);
            }
            &::after {
               top: -15%;
               left: 10%;
               width: 30%;
            }
         }
         &.borderRight {
            border-right: 1px solid rgba(84, 95, 138, 0.2);
         }
         .total {
            font-size: 1.05rem;
            color: $color-1;
            // font-weight: 500;
         }
         .withBorder {
            border-right: 1px solid rgba(84, 95, 138, 0.2);
         }
         .withBorderLeft {
            border-left: 1px solid rgba(84, 95, 138, 0.2);
            // border-top: 1px solid rgba(84, 95, 138, 0.2);
         }
         .icon {
            --val: 3rem;
            width: var(--val);
            height: var(--val);
            margin-right: 1rem;
         }
         .label {
            font-size: .82rem;
            color: $color-8;
         }
         h5 {
            font-size: 1.3rem;
            font-weight: 600;
            color: $color-1;
         }
      }
   }

   .recent {
      .recentOrders, .orderSummary {
         background: #FFFFFF;
         box-shadow: 0px 4px 37px rgba(229, 229, 229, 0.13);
         border-radius: 8px;
      }

      .recentOrders {
         h5 {
            font-size: 1.05rem;
            color: $color-1;
         }
         // .table-responsive {
         //    &::-webkit-scrollbar {
         //       width: 0px; /* Safari and Chrome */
         //       height: 0px; /* Safari and Chrome */
         //    }
         //    &::-webkit-scrollbar-track {
         //       background: transparent;
         //    }
         //    &::-webkit-scrollbar-thumb {
         //       position: absolute;
         //       background-color: $background-12;
         //       border-radius: 30px;
         //    }
         //    table {
         //       thead, tbody, tfoot, tr, td, th {
         //          border: none;
         //       }
         //       thead {
         //          tr {
         //             background: $background-13;
         //             th {
         //                color: $color-9;
         //                padding-top: .5rem;
         //                padding-bottom: .5rem;
         //             }
         //          }
         //       }
         //       tbody {
         //          border: none;
         //          tr {
         //             cursor: pointer;
         //             transition: $trans3;
         //             &:hover {
         //                background: $background-12;
         //             }
         //             &:not(:last-child) {
         //                border-bottom: 1px solid rgba(84, 95, 138, 0.2);
         //             }
         //             .img {
         //                --val: 2.4rem;
         //                width: var(--val);
         //                height: var(--val);
         //                border-radius: 4px;
         //                overflow: hidden;
         //             }
         //             .mealName, td {
         //                font-size: 1rem;
         //                color: $color-1;
         //             }
         //             .mealName {
         //                width: 9rem;
         //             }
         //             td {
         //                .delivered {
         //                   color: $color-10;
         //                }
         //                .onDelivery {
         //                   color: $background-7;
         //                }
         //                .canceled {
         //                   color: $background-1;
         //                }
         //             }
         //          }
         //       }
         //    }
         // }
      }

      .orderSummary {
         h5 {
            font-size: 1.05rem;
         }
         .newOrders {
            border-radius: 4px;
            font-size: .82rem;
            padding: .5rem 1rem;
            transition: $trans3;
            color: $white;
            background: $background-1;
            &:hover {
               background: $background-1a;
            }
         }
         button {
            border-radius: 4px;
            font-size: .82rem;
            padding: .5rem 1rem;
            transition: $trans3;
            &.newOrders {
               color: $white;
               background: $background-1;
               &:hover {
                  background: $background-1a;
               }
            }
            &.manageOrder {
               color: $background-1;
               background: $background-14;
               &:hover {
                  background: $background-14a;
               }
            }
         }
         .status {
            border-radius: 4px;
            font-size: .82rem;
            display: flex;
            align-items: center;
            // background: #ddd;
            p {
               // --value: 2rem;
               width: fit-content;
               height: 1.8rem;
               border-radius: 50%;
               font-size: 1rem;
               display: flex;
               align-items: center;
               justify-content: center;
            }
            small {
               font-size: .82rem;
               color: $color-9;
            }
            &.delivered {
               border: 1px solid rgba(0, 188, 154, 0.06);
               p {
                  font-size: .82rem;
                  color: $color-10;
                  background: rgba(0, 188, 154, 0.06);
               }
            }
            &.onDelivery {
               border: 1px solid rgba(238, 143, 1, 0.06);
               p {
                  font-size: .82rem;
                  color: $background-7;
                  background: rgba(238, 143, 1, 0.06);
               }
            }
            &.canceled {
               border: 1px solid rgba(232, 78, 27, 0.06);
               p {
                  font-size: .82rem;
                  color: $background-1;
                  background: rgba(232, 78, 27, 0.06);
               }
            }
         }
         .chart {
            .doughnut {
               height: 9.86rem;
            }
            .progress {
               border-radius: 16px;
               height: 6px;
            }
            .bg__delivered {
               border-radius: 16px;
               background-color: #00BC9A;
            }
            .bg__onDelivery {
               border-radius: 16px;
               background-color: #EE8F01;
            }
            .bg__canceled {
               border-radius: 16px;
               background-color: #E84E1B;
            }
            span {
               font-size: .82rem;
               &.deliver {
                  color: #424C75;
               }
               &.percent {
                  color: #9EA6C7;
               }
            }
         }
      }
   }

   .revenue {
      background: #FFFFFF;
      box-shadow: 0px 4px 37px rgba(229, 229, 229, 0.13);
      border-radius: 8px;
   }
}