@import '../../variables';

.second {
   h5 {
      font-size: 1.1rem;
   }
   .table-responsive {
      &::-webkit-scrollbar {
         width: 0px; /* Safari and Chrome */
         height: 0px; /* Safari and Chrome */
      }
      &::-webkit-scrollbar-track {
         background: transparent;
      }
      &::-webkit-scrollbar-thumb {
         position: absolute;
         background-color: $background-12;
         border-radius: 30px;
      }
      table {
         thead, tbody, tfoot, tr, td, th {
            border: none;
         }
         thead {
            tr {
               background: $background-13;
               th {
                  color: $color-9;
                  padding-top: .5rem;
                  padding-bottom: .5rem;
               }
            }
         }
         tbody {
            border: none;
            tr {
               cursor: pointer;
               transition: $trans3;
               border-bottom: 1px solid rgba(84, 95, 138, 0.2);
               &:hover {
                  background: $background-12;
               }
               // &:not(:last-child) {
               // }
               .img {
                  --val: 4.5rem;
                  width: var(--val);
                  height: var(--val);
                  border-radius: 4px;
                  overflow: hidden;
                  box-shadow: 0px 4px 25px rgba(155, 155, 155, 0.2);
               }
               .breakfast {
                  color: $background-1;
               }
               .mealName, td {
                  font-size: 1rem;
                  color: $color-1;
               }
               .mealName {
                  width: 73%;
                  line-height: 1.3;
               }
               td {
                  .delivered {
                     color: $color-10;
                  }
                  .onDelivery {
                     color: $background-7;
                  }
                  .canceled {
                     color: $background-1;
                  }
               }
            }
         }
      }
   }
   .val {
      font-size: .9rem;
      color: $color-3;
   }
   .amount {
      font-size: .9rem;
      font-weight: 600;
      color: $color-1;
   }
   .status {
      font-size: 1.1rem;
      color: $color-1;
      .paid {
         font-size: 1.1rem;
         color: $color-10;
         .deliveryOngoing {
            color: $background-4;
         }
      }
      .react-dropdown-select {
         margin-left: 1rem;
         width: fit-content;
         .react-dropdown-select-content {
            span {
               font-size: .9rem;
            }
         }
         .react-dropdown-select-dropdown {
            .react-dropdown-select-item {
               white-space: nowrap;
               font-size: .9rem;
               transition: .3s;
               &:hover {
                  color: $white;
                  background: $background-1;
               }
               &-selected {
                  color: $white;
                  background: $background-1;
               }
            }
         }
      }
   }
   button {
      font-size: 1rem;
      border-radius: 4px;
      padding: .5rem 1rem;
      transition: $trans3;
      &.acceptOrder {
         color: $white;
         background: $background-1;
         &:hover {
            background: $background-1a;
         }
      }
      &.declineOrder {
         color: $background-1;
         border: 1px solid $background-1;
         &:hover {
            opacity: .5;
         }
      }
   }
}