@import '../../variables';

.menuView {
   .back {
      button {
         color: $color-1;
         transition: $trans3;
         &:hover {
            transform: translateX(-2px) translateY(2px);
         }
      }
   }
   .mealDetails, .comments {
      background: #FFFFFF;
      box-shadow: 0px 4px 37px rgba(229, 229, 229, 0.13);
      border-radius: 8px;
   }
   .mealDetails {
      form {
         .img {
            height: 25rem;
            border-radius: 4px;
            overflow: hidden;
            // background: #ddd;
            box-shadow: 0px 4px 25px rgba(155, 155, 155, 0.1);
         }
         .change {
            text-decoration: underline;
            color: $background-1;
         }
         .react-dropdown-select {
            width: 100%;
            padding: .5rem;
            border: 1px solid rgba(84, 95, 138, 0.1);
            .react-dropdown-select-type-multi {
               .react-dropdown-select-option {
                  color: $color-3 !important;
                  background: transparent;
                  border: 1px solid #999 !important;
               }
            }
            .react-dropdown-select-content {
               .react-dropdown-select-input {
                  text-transform: capitalize;
                  color: $color-3;
                  font-size: 1rem;
               }
            }
            .react-dropdown-select-dropdown {
               width: 100%;
               top: 120%;
               .react-dropdown-select-item {
                  font-size: 1rem;
                  color: $color-3;
                  border-bottom: 1px solid rgba(84, 95, 138, 0.1);
                  transition: $trans3;
                  &:hover {
                     color: $white;
                     background: $background-1a;
                  }
                  &-selected {
                     color: $white;
                     background: $background-1;
                  }
               }
            }
         }
         label {
            font-size: .9rem;
            color: $color-3;
         }
         textarea, .dateTime {
            color: $color-3;
            padding: .45rem;
            border-radius: 4px;
            border: 1px solid rgba(84, 95, 138, 0.4);
            &::-webkit-scrollbar {
               width: 4px; /* Safari and Chrome */
            }
            &::-webkit-scrollbar-track {
               background: transparent;
            }
            &::-webkit-scrollbar-thumb {
               position: absolute;
               background-color: $color-3;
               border-radius: 30px;
            }
         }
         .save {
            font-size: 1rem;
            border-radius: 4px;
            padding-top: .7rem;
            padding-bottom: .7rem;
            color: $white;
            background: $background-1;
            transition: $trans3;
            &:hover {
               background: $background-1a;
            }
         }
      }
   }
   .comments {
      &::-webkit-scrollbar {
         width: 6px; /* Safari and Chrome */
      }
      &::-webkit-scrollbar-track {
         background: transparent;
      }
      &::-webkit-scrollbar-thumb {
         position: absolute;
         background-color: $background-12;
         border-radius: 6px;
      }
      h5 {
         font-size: 1.1rem;
      }
      .table-responsive {
         &::-webkit-scrollbar {
            width: 0px; /* Safari and Chrome */
            height: 0px; /* Safari and Chrome */
         }
         &::-webkit-scrollbar-track {
            background: transparent;
         }
         &::-webkit-scrollbar-thumb {
            position: absolute;
            background-color: $background-12;
            border-radius: 30px;
         }
         table {
            thead, tbody, tfoot, tr, td, th {
               border: none;
            }
            thead {
               tr {
                  background: $background-13;
                  th {
                     color: $color-9;
                     padding-top: .5rem;
                     padding-bottom: .5rem;
                  }
               }
            }
            tbody {
               border: none;
               tr {
                  // cursor: pointer;
                  transition: $trans3;
                  border-bottom: 1px solid rgba(84, 95, 138, 0.2);
                  &:hover {
                     // background: $background-12;
                  }
                  // &:not(:last-child) {
                  // }
                  .img {
                     --val: 3rem;
                     width: var(--val);
                     height: var(--val);
                     border-radius: 50%;
                     overflow: hidden;
                     // background: #ddd;
                     box-shadow: 0px 4px 25px rgba(155, 155, 155, 0.2);
                  }
                  .breakfast {
                     color: $background-1;
                  }
                  .mealName, td {
                     font-size: 1rem;
                     color: $color-1;
                  }
                  .mealName {
                     width: 73%;
                     line-height: 1.3;
                  }
                  td {
                     .delivered {
                        color: $color-10;
                     }
                     .onDelivery {
                        color: $background-7;
                     }
                     .canceled {
                        color: $background-1;
                     }
                  }
               }
            }
         }
      }
   }
}
