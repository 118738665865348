@import '../../variables';

.settings {
   background: #FFFFFF;
   box-shadow: 0px 4px 37px rgba(229, 229, 229, 0.13);
   border-radius: 8px;
   animation: $fade1;
   .tabs {
      display: flex;
      align-items: center;
      li {
         font-size: 1rem;
         color: $color-3;
         position: relative;
         transition: $trans3;
         cursor: pointer;
         &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 3px;
            width: 100%;
            transition: $trans3;
         }
         &.active::before, &:hover::before {
            background: $background-1;
         }
         &.active, &:hover {
            color: $background-1;
         }
      }
   }
   .adminUserBtn {
      font-size: .9rem;
      color: $white;
      padding: .7rem 2.5rem;
      border-radius: 4px;
      background: $background-1;
      transition: $trans3;
      &:hover {
         background: $background-1a;
      }
   }
   .profile {
      .info {
         font-size: 1rem;
         color: $color-1;
      }
      .profilePic {
         .img {
            --val: 7rem;
            width: var(--val);
            height: var(--val);
            border-radius: 4px;
            background: #fff;
            box-shadow: 0px 15px 38px rgba(84, 95, 138, 0.05);
            display: flex;
            align-items: center;
            justify-content: center;
         }
         .uploadBtn {
            font-size: .8rem;
            color: $background-1;
            border-radius: 4px;
            background: $background-3a;
            padding: .4rem 1rem;
            transition: $trans3;
            &:hover {
               background: $background-3a;
            }
         }
         .imgSize {
            font-size: .85rem;
            color: $color-3;
            line-height: 1.4;
         }
      }
      form {
         box-shadow: none;
         label {
            font-size: 1rem;
            color: $color-3;
         }
         input {
            font-size: .9rem;
            color: $color-1;
            padding: .5rem 1.2rem;
            border-radius: 4px;
            border: 1px solid $color-4;
            &::placeholder {
               color: $color-4;
            }
         }
         .submit {
            font-size: .9rem;
            color: $white;
            padding: .7rem 2.5rem;
            border-radius: 4px;
            background: $background-1;
            transition: $trans3;
            &:hover {
               background: $background-1a;
            }
         }
      }
   }
   // .security {}
   .payments {
      .info {
         font-size: 1rem;
         color: $color-1;
      }
      small {
         font-size: .85rem;
         color: $color-3;
      }
      .balance {
         font-size: 1.5rem;
         color: $color-3;
      }
      .withdraw {
         font-size: .85rem;
         color: $white;
         background: $background-4;
         border-radius: 8px;
         padding: .5rem 1.2rem;
         transition: $trans3;
         &:hover {
            background: $background-4a;
         }
      }
      .method {
         background: #FFFFFF;
         border: 1px solid #DFE0EB;
         border-radius: 3px;
         h6 {
            color: $color-3;
            padding-bottom: .4rem;
         }
         p {
            color: $color-1;
         }
      }
      .submit {
         font-size: .9rem;
         color: $white;
         padding: .7rem 2.5rem;
         border-radius: 4px;
         background: $background-1;
         transition: $trans3;
         &:hover {
            background: $background-1a;
         }
      }
   }
   .adminUsers {
      .table-responsive {
         &::-webkit-scrollbar {
            width: 0px; /* Safari and Chrome */
            height: 0px; /* Safari and Chrome */
         }
         &::-webkit-scrollbar-track {
            background: transparent;
         }
         &::-webkit-scrollbar-thumb {
            position: absolute;
            background-color: $background-12;
            border-radius: 30px;
         }
         table {
            thead, tbody, tfoot, tr, td, th {
               border: none;
            }
            thead {
               tr {
                  background: $background-13;
                  th {
                     color: $color-9;
                     padding-top: .5rem;
                     padding-bottom: .5rem;
                  }
               }
            }
            tbody {
               border: none;
               cursor: pointer;
               tr {
                  transition: $trans3;
                  &:hover {
                     background: $background-12;
                  }
                  &:not(:last-child) {
                     border-bottom: 1px solid rgba(84, 95, 138, 0.2);
                  }
                  .img {
                     --val: 2.4rem;
                     width: var(--val);
                     height: var(--val);
                     border-radius: 4px;
                     overflow: hidden;
                  }
                  .mealName, td {
                     font-size: 1rem;
                     color: $color-1;
                  }
                  .mealName {
                     width: 11rem;
                  }
                  td {
                     .delivered {
                        color: $color-10;
                     }
                     .onDelivery {
                        color: $background-7;
                     }
                     .canceled {
                        color: $background-1;
                     }
                  }
               }
            }
         }
      }
   }
}
