@import '../../variables';

.pagination {
   li {
      color: $color-1;
      font-size: .9rem;
      font-weight: 600;
      transition: .3s;
      // padding: 4rem;
      cursor: pointer;
      .icon {
         transform: scale(.7);
         cursor: pointer;
      }
      &.prev, &.next {
         border: 1px solid rgba(84, 95, 138, 0.2);
         border-radius: 2px;
         padding: .2rem 1rem;
         &:hover {
            transform: scale(1.03);
         }
         &.disabled {
            &:hover {
               transform: none;
            }
         }
      }
      &.list {
         padding: .3rem .8rem;
         // background: #777;
         border-radius: 3px;
         &:hover, &.active {
            color: #fff;
            background: #E84E1B;
         }
      }
      &.disabled {
         opacity: .3;
         cursor: auto;
         .icon {
            cursor: auto;
         }
      }
   }
}
