@import '../../variables';

.reservations {
   animation: $fade1;
   button {
      color: $color-3;
      font-size: .9rem;
      .react-daterange-picker {
         border-radius: 22px !important;
         transition: .3s;
         .react-daterange-picker__wrapper {
            padding: .5rem 1.2rem !important;
            border: 1px solid rgba(84, 95, 138, 0.5) !important;
            border-radius: 22px !important;
            .react-daterange-picker__inputGroup {
               color: $color-3;
            }
         }
      }
      &.status {
         cursor: pointer;
         .react-dropdown-select {
            border-radius: 22px;
            width: 100%;
            padding: .5rem;
            border: 1px solid rgba(84, 95, 138, 0.5);
            .react-dropdown-select-content {
               .react-dropdown-select-input {
                  text-transform: capitalize;
                  color: $color-3;
                  font-size: .9rem;
               }
            }
            .react-dropdown-select-dropdown {
               width: 100%;
               top: 120%;
               .react-dropdown-select-item {
                  font-size: .9rem;
                  color: $color-3;
                  border-bottom: 1px solid rgba(84, 95, 138, 0.1);
                  transition: $trans3;
                  &:hover {
                     color: $white;
                     background: $background-1;
                  }
                  &-selected {
                     color: $white;
                     background: $background-1;
                  }
               }
            }
         }
      }
      &.applyFilter {
         border-radius: 22px;
         border: 1px solid rgba(84, 95, 138, 0.5);
         transition: .3s;
         padding: .5rem 1rem;
         color: $white;
         border: none;
         background: $background-1;
         transition: .3s;
         &:hover {
            background: $background-1a;
         }
      }
   }
}
