@import '../../variables';

.verifying {
   width: 60%;
   max-width: 90vw;
   background: $white;
   box-shadow: 0px 4px 37px rgba(229, 229, 229, 0.13);
   border-radius: 8px;
   .nutIcon {
      top: -1rem;
      right: -1rem;
      transform: scale(.65);
   }
   .signIn {
      font-size: 1.4rem;
      font-weight: 600;
      color: $color-1;
   }
   .server-error {
      // margin-bottom: 25px;
      // width: 71.3%;

      p {
         color: rgb(231, 22, 22);
         line-height: 14px;
         // margin-left: 10px;
      }
   }
   .small {
      // font-size: .85rem;
      color: $color-3;
   }
   .change {
      text-decoration: underline;
      color: $background-1;
   }
   label {
      font-size: .9rem;
      color: $color-3;
   }
   .dateTime {
      color: $color-3;
      padding: .45rem;
      border-radius: 4px;
      border: 1px solid rgba(84, 95, 138, 0.4);
      &::-webkit-scrollbar {
         width: 4px; /* Safari and Chrome */
      }
      &::-webkit-scrollbar-track {
         background: transparent;
      }
      &::-webkit-scrollbar-thumb {
         position: absolute;
         background-color: $color-3;
         border-radius: 30px;
      }
   }
   .__icon {
      top: .6rem;
      right: .5rem;
      z-index: 2;
      cursor: pointer;
      // background: #ddd;
      width: 1.8rem;
      height: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      i, .far {
         color: $color-1;
      }
   }
   .forgot {
      a {
         font-size: .85rem;
         color: $background-4;
      }
   }
   .sign {
      font-size: 1rem;
      border-radius: 4px;
      padding-top: .7rem;
      padding-bottom: .7rem;
      color: $white;
      background: $background-1;
      transition: $trans3;
      &:hover {
         background: $background-1a;
      }
   }
}
