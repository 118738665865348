@import '../../../../variables';

.confirmPayment {
   background: #FFFFFF;
   box-shadow: 0px 15px 38px rgba(84, 95, 138, 0.05);
   border-radius: 8px;
   h6 {
      font-size: 1.1rem;
      color: $color-1;
   }
   .img {
      --value: 5rem;
      width: var(--value);
      height: var(--value);
      border-radius: 50%;
      background: #ddd;
      overflow: hidden;
   }
   .customerName {
      color: $color-3;
   }
   .orderId {
      font-size: .95rem;
      font-weight: 600;
      color: $color-1;
   }
   .amount {
      small {
         font-size: .85rem;
         color: $color-3;
      }
      h5 {
         font-size: 2rem;
         font-weight: 600;
         color: $color-1;
      }
   }
   .content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 100px;
      column-gap: 1rem;
   }
   .badge {
      text-align: left;
      height: max-content;
      width: 100%;
      background: rgba(217, 217, 217, 0.85);
      // opacity: 0.2;
      border-radius: 2px;
      small {
         font-size: .85rem;
         color: $color-3;
      }
      .paid {
         font-size: 1.1rem;
         color: $color-10;
      }
      .mmm {
         font-size: 1.1rem;
         color: $color-3;
      }
   }
   button {
      color: $white;
      border-radius: 4px;
      padding-top: 1rem;
      padding-bottom: 1rem;
      background: $background-1;
      transition: $trans3;
      &:hover {
         background: $background-1a;
      }
   }
}