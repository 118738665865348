@import '../../variables';

.category {
   background: #FFFFFF;
   box-shadow: 0px 4px 37px rgba(229, 229, 229, 0.13);
   border-radius: 8px;
   animation: $fade1;
   .search {
      width: 22rem;
      form {
         input {
            font-size: 1rem;
            color: $color-1;
            padding-left: 1.5rem;
            padding-bottom: .2rem;
            border: none;
            border-bottom: 1px solid rgba(84, 95, 138, 0.2);
            &::placeholder {
               color: rgba(84, 95, 138, 0.7);
            }
         }
         .icon__Wrapper {
            left: 0;
            top: 0;
            width: 1rem;
         }
      }
   }
   .react-dropdown-select {
      width: 12rem;
      padding: .5rem;
      border: 1px solid rgba(84, 95, 138, 0.1);
      .react-dropdown-select-content {
         .react-dropdown-select-input {
            text-transform: capitalize;
            color: $color-3;
            font-size: 1rem;
         }
      }
      .react-dropdown-select-dropdown {
         width: 100%;
         top: 120%;
         .react-dropdown-select-item {
            font-size: 1rem;
            color: $color-3;
            border-bottom: 1px solid rgba(84, 95, 138, 0.1);
            transition: $trans3;
            &:hover {
               color: $white;
               background: $background-1a;
            }
            &-selected {
               color: $white;
               background: $background-1;
            }
         }
      }
   }
   button {
      font-size: 1rem;
      border-radius: 4px;
      padding: .5rem 1.5rem;
      transition: $trans3;
      &.addMenu {
         color: $white;
         background: $background-1;
         &:hover {
            background: $background-1a;
         }
      }
      // &.declineOrder {
      //    color: $background-1;
      //    border: 1px solid $background-1;
      //    &:hover {
      //       opacity: .5;
      //    }
      // }
   }
   .table-responsive {
      &::-webkit-scrollbar {
         width: 0px; /* Safari and Chrome */
         height: 0px; /* Safari and Chrome */
      }
      &::-webkit-scrollbar-track {
         background: transparent;
      }
      &::-webkit-scrollbar-thumb {
         position: absolute;
         background-color: $background-12;
         border-radius: 30px;
      }
      table {
         thead, tbody, tfoot, tr, td, th {
            border: none;
         }
         thead {
            tr {
               background: $background-13;
               th {
                  color: $color-9;
                  padding-top: .5rem;
                  padding-bottom: .5rem;
               }
            }
         }
         tbody {
            border: none;
            .no-content {
               font-size: 1.5rem;
               margin-top: 10%;
            }
            tr {
               // cursor: pointer;
               transition: $trans3;
               border-bottom: 1px solid rgba(84, 95, 138, 0.2);
               &:hover {
                  background: $background-12;
               }
               // &:not(:last-child) {
               // }
               .img {
                  --val: 4rem;
                  width: var(--val);
                  height: var(--val);
                  border-radius: 4px;
                  overflow: hidden;
                  // background: #ddd;
                  box-shadow: 0px 4px 25px rgba(155, 155, 155, 0.2);
               }
               .breakfast {
                  color: $background-1;
               }
               &.cat {
                  td {
                     // padding-left: .5rem;
                     // padding-right: .5rem;
                  }
               }
               .mealName, td {
                  font-size: 1rem;
                  color: $color-1;
               }
               .mealName {
                  width: 73%;
                  line-height: 1.3;
               }
               .desc {
                  width: 13rem;
                  display: block;
                  display: -webkit-box;
                  overflow: hidden !important;
                  text-overflow: ellipsis;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
               }
               td {
                  .delivered {
                     color: $color-10;
                  }
                  .onDelivery {
                     color: $background-7;
                  }
                  .canceled {
                     color: $background-1;
                  }
                  
               }
            }
         }
      }
   }
}