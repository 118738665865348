
.fullScreenModal {
   position: fixed;
   z-index: 1250;
   bottom: 0;
   right: 0;
   left: 0;
   top: 0;

   .fullScreenModal__BackDrop {
      background: rgba(9, 20, 40, 0.74);
      backdrop-filter: blur(15px);
      position: absolute;
      z-index: -1;
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
   }
   .fullScreenModal__Contents {
      height: fit-content;
      max-height: 90vh;
      width: fit-content;
      max-width: 90vw;
      overflow-y: scroll;
      border-radius: 8px;

      &::-webkit-scrollbar {
         width: 0; /* Safari and Chrome */
      }
      &::-webkit-scrollbar-track {
         // background: #1a1a1a;
         background: transparent;
      }
      &::-webkit-scrollbar-thumb {
         position: absolute;
         background-color: #383838;
         border-radius: 30px;
      }
   }
}
