@import '../../variables';

.sidebar {
   width: 18%;
   min-width: 235px;
   height: 100vh;
   overflow: hidden;
   background: #FFFFFF;
   box-shadow: 0px 4px 37px rgba(229, 229, 229, 0.13);
   z-index: 1050;

   &::-webkit-scrollbar {
      width: 0; /* Safari and Chrome */
   }
   &::-webkit-scrollbar-track {
      background: transparent;
   }
   &::-webkit-scrollbar-thumb {
      position: absolute;
      background-color: #383838;
      border-radius: 6px;
   }
   &.hidden {
      width: 0;
      min-width: 0;
      pointer-events: none;
      
      & > * {
         pointer-events: none;
      }
   }
   .content {
      height: 100%;
      overflow-x: hidden;
      position: relative;
      overflow-y: scroll;
      padding-bottom: 3rem;
      &::-webkit-scrollbar {
         width: 0; /* Safari and Chrome */
      }
      &::-webkit-scrollbar-track {
         background: transparent;
      }
      &::-webkit-scrollbar-thumb {
         position: absolute;
         background-color: #383838;
         border-radius: 30px;
      }
      #sidebarIcon {
         position: absolute;
         right: .7rem;
         top: .5rem;
         cursor: pointer;
      }
      .logo {
         // width: 6.375rem;
         img {
            width: 3.5rem;
         }
         span {
            font-size: .75rem;
            font-weight: 600;
            color: $background-1;
         }
      }
      .routes {
         a {
            li {
               font-size: .82rem;
               color: $color-8;
               margin-bottom: 1rem;
               transition: $trans2;
               // background-color: red;
               &:hover, &.active {
                  color: $background-1;
                  transform: scale(1.1) translateY(-2px) translateX(6px);
               }
               .icon__wrapper {
                  --value: 2rem;
                  width: var(--value);
                  height: var(--value);
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  transition: $trans4;
                  &.active, &:hover {
                     background: $background-12;
                  }
                  &.first {
                     svg {
                        transform: scale(.6);
                     }
                  }
                  &.second {
                     svg {
                        transform: scale(.55);
                     }
                  }
                  &.third {
                     svg {
                        transform: scale(.65);
                     }
                  }
                  &.fourth {
                     svg {
                        transform: scale(.55);
                     }
                  }
                  &.fifth {
                     svg {
                        transform: scale(.7);
                     }
                  }
                  &.sixth {
                     svg {
                        transform: scale(.65);
                     }
                  }
                  &.seventh {
                     svg {
                        transform: scale(.65);
                     }
                  }
                  &.eighth {
                     svg {
                        transform: scale(.65);
                     }
                  }
                  &.ninth {
                     svg {
                        transform: scale(.55);
                     }
                  }
                  &.tenth {
                     svg {
                        transform: scale(.6);
                     }
                  }
               }
               &:hover .icon__wrapper {
                  background: $background-12;
               }
               p {
                  font-size: 1rem;
               }
            }
         }
      }
      .signOut {
         font-size: .82rem;
         color: $background-1;
         padding: .5rem 1rem;
         border-radius: 6px;
         border: 1px solid $background-1;
         transition: $trans2;
         &:hover {
            color: $white;
            border: 1px solid transparent;
            background: $background-1;
         }
         .icon {
            transition: $trans3;
         }
         &:hover .icon {
            color: $white;
         }
      }
   }
}
