@import '../../../../variables';

.dialog {
   button {
      font-size: .9rem;
      padding: .5rem 1.2rem;
      transition: .3s;
      border-radius: 4px;
      &.delete {
         color: $white;
         background: $error;
         &:hover {
            background: $errorA;
         }
      }
      &.cancel {
         color: $color-3;
         border: 1px solid $color-3;
         background: $color-2;
         &:hover {
            opacity: .6;
         }
      }
   }
}
