@import '../../variables';

.customerView {
   h4 {
      font-size: 1.2rem;
      font-weight: 600;
      color: $color-1;
   }
   .customerImg {
      --value: 10rem;
      width: var(--value);
      height: var(--value);
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;
      background: #ddd;
   }
   .customerName {
      font-size: 1rem;
      color: $color-3;
   }
   .customer {
      font-size: 1rem;
      color: $background-1;
   }
}
