@import '../../variables';

.orders {
   background: #FFFFFF;
   box-shadow: 0px 4px 37px rgba(229, 229, 229, 0.13);
   border-radius: 8px;
   animation: $fade1;
   h5 {
      font-size: 1.1rem;
   }
   .search {
      form {
         input {
            font-size: 1rem;
            color: $color-1;
            padding-left: 1.5rem;
            padding-bottom: .2rem;
            border: none;
            border-bottom: 1px solid rgba(84, 95, 138, 0.2);
            &::placeholder {
               color: rgba(84, 95, 138, 0.7);
            }
         }
         .icon__Wrapper {
            left: 0;
            top: 0;
            width: 1rem;
         }
      }
   }
   .table-responsive {
      &::-webkit-scrollbar {
         width: 0px; /* Safari and Chrome */
         height: 0px; /* Safari and Chrome */
      }
      &::-webkit-scrollbar-track {
         background: transparent;
      }
      &::-webkit-scrollbar-thumb {
         position: absolute;
         background-color: $background-12;
         border-radius: 30px;
      }
      table {
         thead, tbody, tfoot, tr, td, th {
            border: none;
         }
         thead {
            tr {
               background: $background-13;
               th {
                  color: $color-9;
                  padding-top: .5rem;
                  padding-bottom: .5rem;
               }
            }
         }
         tbody {
            border: none;
            tr {
               cursor: pointer;
               transition: $trans3;
               &:hover {
                  background: $background-12;
               }
               &:not(:last-child) {
                  border-bottom: 1px solid rgba(84, 95, 138, 0.2);
               }
               .img {
                  --val: 2.4rem;
                  width: var(--val);
                  height: var(--val);
                  border-radius: 4px;
                  overflow: hidden;
               }
               .mealName, td {
                  font-size: 1rem;
                  color: $color-1;
               }
               .mealName {
                  // width: 11rem;
               }
               .id {
                  font-weight: 600;
               }
               td {
                  .delivered {
                     color: $color-10;
                  }
                  .onDelivery {
                     color: $background-7;
                  }
                  .canceled {
                     color: $background-1;
                  }
                  ul {
                     right: 0;
                     background: #FFFFFF;
                     box-shadow: 0px 3px 33px rgba(121, 120, 120, 0.13);
                     border-radius: 8px;
                     z-index: 501;
                     overflow: hidden;
                     li {
                        padding: .4rem 1rem;
                        cursor: pointer;
                        transition: .3s;
                        font-size: .9rem;
                        &:hover {
                           background: rgba(225, 225, 225, 0.3);
                        }
                        .icon {
                           transform: scale(.7);
                        }
                     }
                  }
               }
            }
         }
      }
   }
}
