@import '../../../../variables';

.addMenu {
   background: #FFFFFF;
   border-radius: 8px;
   h5 {
      font-size: 1.3rem;
      color: $color-1;
   }
   h6 {
      font-size: .85rem;
      color: $color-3;
   }
   span {
      font-size: .9rem;
      color: $color-3;
   }
   .img {
      --val: 7rem;
      width: var(--val);
      height: var(--val);
      // border: 1px solid rgba(84, 95, 138, 0.4);
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0px 4px 25px rgba(155, 155, 155, 0.2);
   }
   .error {
      color: red;
   }
   .choose {
      font-size: .9rem;
      color: $background-1;
      text-decoration: underline;
      transition: $trans3;
      &:hover {
         color: $background-1a;
      }
   }
   p {
      font-size: .85rem;
      color: $color-3;
   }
   form {
      .react-dropdown-select {
         width: 100%;
         padding: .5rem;
         border: 1px solid rgba(84, 95, 138, 0.1);
         .react-dropdown-select-content {
            .react-dropdown-select-input {
               text-transform: capitalize;
               color: $color-3;
               font-size: 1rem;
            }
         }
         .react-dropdown-select-dropdown {
            width: 100%;
            top: 120%;
            .react-dropdown-select-item {
               font-size: 1rem;
               color: $color-3;
               border-bottom: 1px solid rgba(84, 95, 138, 0.1);
               transition: $trans3;
               &:hover {
                  color: $white;
                  background: $background-1;
               }
               &-selected {
                  color: $white;
                  background: $background-1;
               }
            }
         }
      }
      label {
         font-size: .9rem;
         color: $color-3;
      }
      textarea {
         padding: .45rem;
         border-radius: 4px;
         border: 1px solid rgba(84, 95, 138, 0.4);
         &::-webkit-scrollbar {
            width: 4px; /* Safari and Chrome */
         }
         &::-webkit-scrollbar-track {
            background: transparent;
         }
         &::-webkit-scrollbar-thumb {
            position: absolute;
            background-color: $color-3;
            border-radius: 30px;
         }
      }
      .save {
         font-size: 1rem;
         border-radius: 4px;
         padding-top: .7rem;
         padding-bottom: .7rem;
         color: $white;
         background: $background-1;
         transition: $trans3;
         &:hover {
            background: $background-1a;
         }
      }
   }
}
