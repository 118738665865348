@import '../../variables';

.notice {
   position: absolute;
   top: 2rem;
   right: 3rem;
   background: #FFFFFF;
   box-shadow: 0px 6px 22px rgba(85, 108, 213, 0.12);
   border-radius: 3px;
   z-index: 2000;
   // height: 50px;

   &.success {
      background-color: #ACE4B9;
   }
   &.error {
      background-color: #F51616;
   }
   .img {
      --value: 4rem;
      width: var(--value);
      height: var(--value);
      border-radius: 4px;
      overflow: hidden;
   }
   span {
      font-size: .85rem;
      color: $color-3;
   }
   a {
      font-size: .85rem;
      text-decoration: underline;
      color: $background-1;
      transition: $trans3;
      &:hover {
         color: $background-1a;
      }
   }
}
