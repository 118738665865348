@import '../../variables';

.payments {
   .search {
      width: 22rem;
      form {
         input {
            font-size: 1rem;
            color: $color-1;
            padding-left: 1.5rem;
            padding-bottom: .2rem;
            border: none;
            border-bottom: 1px solid rgba(84, 95, 138, 0.2);
            &::placeholder {
               color: rgba(84, 95, 138, 0.7);
            }
         }
         .icon__Wrapper {
            left: 0;
            top: 0;
            width: 1rem;
         }
      }
   }
}
