@import '../../variables';

.OrderView {
   animation: $fade1;
   .back {
      button {
         color: $color-1;
         transition: $trans3;
         &:hover {
            transform: translateX(-2px) translateY(2px);
         }
      }
   }
   .OrderDetails {
      .first, .second, .third, .fourth {
         background: #FFFFFF;
         box-shadow: 0px 4px 37px rgba(229, 229, 229, 0.13);
         border-radius: 8px;
         height: fit-content;
      }

      // .first {
      //    h4 {
      //       font-size: 1.2rem;
      //       font-weight: 600;
      //       color: $color-1;
      //    }
      //    .img {
      //       --value: 10rem;
      //       width: var(--value);
      //       height: var(--value);
      //       border-radius: 50%;
      //       overflow: hidden;
      //       margin: 0 auto;
      //       background: #ddd;
      //    }
      //    .customerName {
      //       font-size: 1rem;
      //       color: $color-3;
      //    }
      //    .customer {
      //       font-size: 1rem;
      //       color: $background-1;
      //    }
      //    h5 {
      //       font-size: 1.2rem;
      //       font-weight: 600;
      //       color: $color-3;
      //    }
      //    .noteOrder {
      //       font-size: 1rem;
      //       color: $color-3;
      //    }
      //    .icon {
      //       width: 3rem;
      //    }
      //    .address {
      //       font-size: 1rem;
      //       color: $background-1;
      //       line-height: 1.3;
      //    }
      // }

      // .second {
      //    h5 {
      //       font-size: 1.1rem;
      //    }
      //    .table-responsive {
      //       &::-webkit-scrollbar {
      //          width: 0px; /* Safari and Chrome */
      //          height: 0px; /* Safari and Chrome */
      //       }
      //       &::-webkit-scrollbar-track {
      //          background: transparent;
      //       }
      //       &::-webkit-scrollbar-thumb {
      //          position: absolute;
      //          background-color: $background-12;
      //          border-radius: 30px;
      //       }
      //       table {
      //          thead, tbody, tfoot, tr, td, th {
      //             border: none;
      //          }
      //          thead {
      //             tr {
      //                background: $background-13;
      //                th {
      //                   color: $color-9;
      //                   padding-top: .5rem;
      //                   padding-bottom: .5rem;
      //                }
      //             }
      //          }
      //          tbody {
      //             border: none;
      //             tr {
      //                cursor: pointer;
      //                transition: $trans3;
      //                border-bottom: 1px solid rgba(84, 95, 138, 0.2);
      //                &:hover {
      //                   background: $background-12;
      //                }
      //                // &:not(:last-child) {
      //                // }
      //                .img {
      //                   --val: 4.5rem;
      //                   width: var(--val);
      //                   height: var(--val);
      //                   border-radius: 4px;
      //                   overflow: hidden;
      //                }
      //                .breakfast {
      //                   color: $background-1;
      //                }
      //                .mealName, td {
      //                   font-size: 1rem;
      //                   color: $color-1;
      //                }
      //                .mealName {
      //                   width: 73%;
      //                   line-height: 1.3;
      //                }
      //                td {
      //                   .delivered {
      //                      color: $color-10;
      //                   }
      //                   .onDelivery {
      //                      color: $background-7;
      //                   }
      //                   .canceled {
      //                      color: $background-1;
      //                   }
      //                }
      //             }
      //          }
      //       }
      //    }
      //    .val {
      //       font-size: .9rem;
      //       color: $color-3;
      //    }
      //    .amount {
      //       font-size: .9rem;
      //       font-weight: 600;
      //       color: $color-1;
      //    }
      //    .status {
      //       font-size: 1.1rem;
      //       color: $color-1;
      //       .paid {
      //          font-size: 1.1rem;
      //          color: $color-10;
      //          .deliveryOngoing {
      //             color: $background-4;
      //          }
      //       }
      //    }
      //    button {
      //       font-size: 1rem;
      //       border-radius: 4px;
      //       padding: .5rem 1rem;
      //       transition: $trans3;
      //       &.acceptOrder {
      //          color: $white;
      //          background: $background-1;
      //          &:hover {
      //             background: $background-1a;
      //          }
      //       }
      //       &.declineOrder {
      //          color: $background-1;
      //          border: 1px solid $background-1;
      //          &:hover {
      //             opacity: .5;
      //          }
      //       }
      //    }
      // }

      .third {
         h5 {
            font-size: 1.1rem;
         }
         ul {
            li {
               position: relative;
               &:not(:last-child)::before {
                  content: '';
                  position: absolute;
                  top: .8rem;
                  left: .8rem;
                  height: 100%;
                  border-left: 3px dotted rgba(232, 78, 27, 0.4);
                  // border-left: 2px dashed rgba(232, 78, 27, 0.4);
               }
               p, small {
                  color: $color-3;
               }
            }
         }
      }

      .fourth {
         .map {
            width: 100%;
            height: 15rem;
            background: #ddd;
            border-radius: 8px;
         }
         .icon__wrapper {
            width: 100%;
         }
         .deliveryBy {
            h6 {
               font-size: 1.1rem;
               color: $color-1;
            }
            .dispatchProfile {
               .profile {
                  .img {
                     --val: 3.5rem;
                     width: var(--val);
                     height: var(--val);
                     border-radius: 50%;
                     overflow: hidden;
                  }
                  .name {
                     font-size: 1rem;
                     color: $color-3;
                  }
                  small {
                     font-size: .85rem;
                     color: $background-1;
                  }
               }
               .date {
                  font-size: 1rem;
                  color: $color-3;
               }
            }
            .deliveryAddress {
               p {
                  font-size: 1rem;
                  color: $color-1;
                  span {
                     color: $color-3;
                  }
               }
               a {
                  font-size: 1rem;
                  color: $color-3;
               }
            }
         }
      }
   }
}
