@keyframes ball-scale-ripple-multiple {
   0% {
      -webkit-transform: scale(0.1);
      transform: scale(0.1);
      opacity: 1;
   }

   70% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 0.7;
   }

   100% {
      opacity: 0.0;
   }
}

@keyframes rotate {
   0% {
      transform: rotate(0deg);
   }

   50% {
      transform: rotate(180deg);
   }

   100% {
      transform: rotate(360deg);
   }
}

.ball-scale-ripple-multiple {
   position: relative;
   -webkit-transform: translateY(-25px);
   transform: translateY(-25px);
}

.ball-scale-ripple-multiple>div:nth-child(0) {
   -webkit-animation-delay: -0.8s;
   animation-delay: -0.8s;
}

.ball-scale-ripple-multiple>div:nth-child(1) {
   -webkit-animation-delay: -0.6s;
   animation-delay: -0.6s;
}

.ball-scale-ripple-multiple>div:nth-child(2) {
   -webkit-animation-delay: -0.4s;
   animation-delay: -0.4s;
}

.ball-scale-ripple-multiple>div:nth-child(3) {
   -webkit-animation-delay: -0.2s;
   animation-delay: -0.2s;
}

.ball-scale-ripple-multiple>div {
   -webkit-animation-fill-mode: both;
   animation-fill-mode: both;
   position: absolute;
   top: -2px;
   left: -26px;
   width: 100px;
   height: 100px;
   border-radius: 100%;
   border: 2px solid #fff;
   -webkit-animation: ball-scale-ripple-multiple 1.25s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
   animation: ball-scale-ripple-multiple 1.25s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
}

.ball-clip-rotate>div {
   border-radius: 100%;
   margin: 2px;
   border: 2px solid #8c9ba5;
   border-bottom-color: transparent;
   height: 90px;
   width: 90px;
   background: 0 0 !important;
   display: inline-block;
   -webkit-animation: rotate .75s 0s linear infinite;
   animation: rotate .75s 0s linear infinite;
}

.loader-wrapper {
   flex: 1;
   display: flex;
   justify-content: center;
   align-items: center
}

.green-loader {
   background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
   background-size: 400% 400%;
   animation: Gradient 15s ease infinite;
}

@keyframes Gradient {
   0% {
      background-position: 0% 50%
   }

   50% {
      background-position: 100% 50%
   }

   100% {
      background-position: 0% 50%
   }
}