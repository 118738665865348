@import '../../variables';

.header {
   position: fixed;
   height: 5rem;
   width: calc(100vw - 18%);
   min-width: calc(100% - 235px);
   background: #FFFFFF;
   box-shadow: 0px 4px 37px rgba(229, 229, 229, 0.13);
   @media (max-width: 1090px) {
      width: 100%;
      min-width: 100%;
   }
   .menu__icon {
      --size: 1.3rem;
      width: var(--size);
      height: var(--size);
   }
   .content {
      .pageName {
         font-size: 1rem;
         color: $color-1;
         width: 150px;
      }
      .search {
         form {
            width: 20rem;
            input {
               width: 100%;
               font-size: .82rem;
               color: $color-1;
               padding-left: 1.5rem;
               padding-bottom: .2rem;
               border: none;
               border-bottom: 1px solid rgba(84, 95, 138, 0.2);
               &::placeholder {
                  color: rgba(84, 95, 138, 0.7);
               }
            }
            .icon__Wrapper {
               left: 0;
               top: 0;
               width: .8rem;
            }
         }
      }
      .pill {
         .notification {
            --value: 1rem;
            width: var(--value);
            height: var(--value);
            .new {
               --val: .4rem;
               width: var(--val);
               height: var(--val);
               top: -.1rem;
               right: 0rem;
               border-radius: 50%;
               background: $background-1;
            }
         }
         .profile {
            // border-left: 1px solid #DFE0EB;
            .username {
               font-size: .82rem;
            }
            .img {
               --val: 2.5rem;
               width: var(--val);
               height: var(--val);
               border-radius: 50%;
               overflow: hidden;
               border: 1px solid #DFE0EB;
               box-shadow: 0px 4px 31px rgba(184, 184, 184, 0.48);
            }
         }
      }
   }
}