
@keyframes fadeIn {
   from {
      opacity: 0;
      margin-top: 20px;
   }

   to {
      opacity: 1;
      margin-top: 0;
   }
}

@keyframes slideIn {
   from {
      opacity: 0;
      transform: translateY(-100%);
   }

   to {
      opacity: 1;
      transform: translateY(0%);
   }
}

@keyframes slideIn2 {
   from {
      opacity: 0;
      right: -100%;
   }

   to {
      opacity: 1;
      right: 0% !important;
   }
}

$fade1: fadeIn .15s ease-in;
$slide: slideIn .15s ease-in;
$slide2: slideIn2 .5s ease-in;

$trans1: all .3s ease-in;
$trans2: all .25s ease-in;
$trans3: all .2s ease-in;
$trans4: all .15s ease-in;

$font1: "ProductSans";
$font2: "FuturaMdBt";
$error: #F51616;
$errorA: #F5161698;
$white: #ffffff;
$black: #000000;

///--- COLORS ---///
$color-1: #091428;
$color-1a: #09142898;
$color-2: rgba(255, 255, 255, 0.8);
$color-2a: rgba(255, 255, 255, 0.4);
$color-3: #545F8A;
$color-4: rgba(84, 95, 138, 0.5);
$color-5: rgba(9, 20, 40, 0.55);
$color-6: #E04F5F;
$color-7: #C47906;
$color-8: rgba(84, 95, 138, 0.7);
$color-9: rgba(84, 95, 138, 0.92);
$color-10: #00BC9A;

$background-1: #E84E1B;
$background-1a: #E84E1B98;
$background-2: #1D1810;
$background-3: #FFF6F3;
$background-3a: #fff9f8;
$background-4: #EE8F01;
$background-4a: #EE8F0198;
$background-5: linear-gradient(180deg, rgba(0, 0, 0, .7) 100%, rgba(0, 0, 0, 0) 10%);
$background-6: rgba(238, 104, 1, 0.156);
$background-7: #F29306;
$background-8: #E5E5E5;
$background-9: rgba(229, 229, 229, 0.6);
$background-10: #FFE9E8;
$background-10a: #FFE9E898;
$background-11: #FFFAF9;
$background-12: rgba(232, 78, 27, 0.1);
$background-13: rgba(84, 95, 138, 0.03);
$background-14: rgba(232, 78, 27, 0.07);
$background-14a: rgba(232, 78, 27, 0.15);
$background-15: #fff3f0;
